import $ from "jquery";
import sal from "sal.js";
import {Tween24, Ease24} from "tween24";
import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import SimpleBar from 'simplebar';


import SimpleParallax from 'simple-parallax-js';
import Lenis from '@studio-freight/lenis';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

const lenis = new Lenis();

const mq = (device) => {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  }
  return window.matchMedia('(min-width:769px)').matches && device === 'pc';
};



// Menu UI Functions
const menuToggleFunc = () => {
  const mTrigger = document.getElementById("menu-trigger");
  mTrigger.addEventListener("click", () => {
    if ($('body').hasClass('menu-show')) {
      $('body').removeClass('menu-show');
    } else {
      $('body').addClass('menu-show');
    }
  });
}


const topFirstSlider = () => {
  const swiper = new Swiper(".top-first-slider", {
        autoplay: {
          delay: 2400,
      },
      loop: true,
      speed: 2400,
      effect: "fade",
      pagination: {
          el: ".swiper-pagination",
      },
  });
}

const firstAnimationTop = () =>{
  const firstVideo = document.getElementById("firstVideo");
  const firstCatch = document.getElementById("firstCatch");
  const promotionButton = document.getElementById("promotionButton");
  const headerWrap = document.getElementById("headerWrap");
  // firstVideoを遅延してautoplayを付与する
  setTimeout(() => {
    firstCatch.classList.add('show');
  }, 400);
 setTimeout(() => {
    headerWrap.classList.add('show');
  }, 1000);

   setTimeout(() => {
    firstVideo.play();
    promotionButton.classList.add('show');
  }, 2000);


  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  // no-new rule is ignored in this case because simpleParallax constructor is being used to create an instance
  // and side effects are expected
  const jsParallax0 = document.getElementsByClassName('js-parallax0');
  const simpleParallax0 = new SimpleParallax(jsParallax0, {
    delay: .3,
    transition: 'cubic-bezier(0,0,0,1)'
  });

  const jsParallax1 = document.getElementsByClassName('js-parallax1');
  const simpleParallax1 = new SimpleParallax(jsParallax1, {
    delay: .3,
    transition: 'cubic-bezier(0,0,0,1)'
  });

  const jsParallax2 = document.getElementsByClassName('js-parallax2');
  const simpleParallax2 = new SimpleParallax(jsParallax2, {
    delay: .6,
    transition: 'cubic-bezier(0,0,0,1)'
  });

  topFirstSlider();

}




/**
 * Lenis js を使ったスムーススクロール
 * @param {param} targetClass
 * @return {void}
 */

const smoothScrollFromAnchor = (targetClass) => {

  const targetLinks = document.querySelectorAll(targetClass);
  targetLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const targetId = link.getAttribute('href');
      lenis.scrollTo(targetId);
    });
  });
}

const subPageInitFuntions = () =>{
  const subFirstContents = document.querySelector('.sub-first-contents');
  const headerWrap = document.getElementById("headerWrap");

  subFirstContents.classList.add('show');
  setTimeout(() => {
    headerWrap.classList.add('show');
  }, 1000);


  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

}




  const formCheck = () => {
    // エラーを表示する関数（error クラスの p 要素を追加して表示）
    function showError(message, this$) {
      let text = this$.parent().find("label").text() + message;
      this$.parent().append(`<p class='error'>${  text  }</p>`);
    }

    // フォームが送信される際のイベントハンドラの設定
    // eslint-disable-next-line consistent-return
    $("#formPage1").submit( () => {
      // エラー表示の初期化
      $("p.error").remove();
      $("div").removeClass("error");
      let text = "";
      $("#errorDispaly").remove();

      // メールアドレスの検証
      let email = $.trim($("#email").val());
      if (
        email &&
        // eslint-disable-next-line no-useless-escape
        !/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi.test(
          email
        )
      ) {
        $("#email").after(
          "<p class='error'>メールアドレスの形式が異なります</p>"
        );
      }

      // 電話番号の検証
      let tel = $.trim($("#tel").val());
      if (
        tel &&
        // eslint-disable-next-line no-useless-escape
        !/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi.test(tel)
      ) {
        $("#tel").after(
          "<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>"
        );
      }

      // カナの検証
      let kana = $.trim($("#kana").val());
      if (kana && !kana.match(/^[ァ-ヶー]+$/)) {
        $("#kana").after(
          "<p class='error'>全角カタカナではない文字が含まれています</p>"
        );
      }

      // 1行テキスト入力フォームとテキストエリアの検証
      $(":text,textarea")
        .filter(".validate")
        // eslint-disable-next-line func-names
        .each( function () {
          // 必須項目の検証
          $(this)
            .filter(".required")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val() === "") {
                showError("必須項目です", $(this));
              }
            });
          // 文字数の検証
          $(this)
            .filter(".max30")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 30) {
                showError(" は30文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max50")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 50) {
                showError(" は50文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max100")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 100) {
                showError(" は100文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max1000")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 1000) {
                showError(" は1000文字以内でお願いします", $(this));
              }
            });
        });

      // error クラスの追加の処理
      if ($("p.error").length > 0) {
        $("p.error").parent().addClass("error");
        $("html,body").animate(
          { scrollTop: $("p.error:first").offset().top - 180 },
          "slow"
        );
        return false;
      }
    });


  };


window.addEventListener('DOMContentLoaded', (event) => {
  menuToggleFunc();
  sal();
  smoothScrollFromAnchor(".js-smooth-scroll");

  if(document.getElementById('topPage')){
    firstAnimationTop();
  }

  if(document.getElementById('subPage')){
    subPageInitFuntions();
  }

  if (document.getElementById("formPage1")) {
    formCheck();
  }

});
